
import './App.css';
import MainLayout from './components/MainLayout';

function App() {
  return (
    <div>
      <MainLayout />
    </div>
  );
}

export default App;
