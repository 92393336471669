import React, { useEffect, useState } from 'react';
import dogWalkImage from '../assets/dog_walk_orange.png';

const WelcomeView = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to update the isMobile state based on viewport width
    const checkIsMobile = () => {
      const viewportWidth = window.innerWidth;
      setIsMobile(viewportWidth <= 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    checkIsMobile();

    // Add event listener to update isMobile when viewport width changes
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);



  return (
    <div className="welcome-view">
      <div class="center">
        {
          !isMobile ? (
            <img src={dogWalkImage} alt="Dog Walking" class=""/>) :
            
            (<img src={dogWalkImage} alt="Dog Walking" class="mobile-image"/>)
        }
      </div>
      <div class="center">
        <h1 class="welcome-header">Let's walk!</h1>
      </div>
      <div class="center">
        <p class="welcome-body">We are conducting a study to understand the needs of dog-walkers and those who use dog sitting apps. 

        Volunteering to join our user research list will help provide us with valuable feedback in the future.
        </p>
      </div>
      <div class="center">
        <p class="welcome-body">Thank you for your participation!</p>
      </div>
    </div>
  );
};

export default WelcomeView;
