import React, { useEffect, useState } from 'react';
import LeftComponent from './WelcomeView';
import RightComponent from './EmailForm';

const MainLayout = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Function to update the isMobile state based on viewport width
    const checkIsMobile = () => {
      const viewportWidth = window.innerWidth;
      setIsMobile(viewportWidth <= 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    checkIsMobile();

    // Add event listener to update isMobile when viewport width changes
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <div>
      { !isMobile ? (
              <div className="main-layout">
                <div className="blue-side">
                  <LeftComponent />
                </div>
                <div className="white-side">
                  <RightComponent />
                </div>
            </div>
      ) : (
        <div className="mobile-layout">
          <div className="mobile-top-layout">
            <LeftComponent />
          </div>
          <div className="mobile-bottom-layout">
            <RightComponent />
          </div>
        </div>
      )

      }
    </div>
  );
};

export default MainLayout;
