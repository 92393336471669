import React, { useState } from 'react';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { doc, setDoc } from "firebase/firestore"; 


function EmailForm() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userDidSumbit, setSubmitStatus] = useState(false);

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBw66XQxl3Mnj3TJzfZo0t0kyMRF-6PgPg",
    authDomain: "wooferresearch.firebaseapp.com",
    projectId: "wooferresearch",
    storageBucket: "wooferresearch.appspot.com",
    messagingSenderId: "73836977251",
    appId: "1:73836977251:web:7d48ff3c8785de0bb52c56",
    measurementId: "G-T7HPDX3593"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const analytics = getAnalytics(app);

  const theme = extendTheme({
    colors: {
      button: {
        primary: '#9CA4D5',
        hover: '#7d83b2'
      },
    },
  });

  async function sendToFirebase() {
    try {
      const emailListRef = collection(db, "email_list");
      const docRef = doc(db, "email_list", email);
      await setDoc(docRef, {
        name: name,
        email: email
      });
  
      console.log("Document added successfully!");
    } catch (error) {
      console.error("Error adding document:", error);
    }
  }

  const handleSubmission = (e) => {
    e.preventDefault();
    if (email.length > 5 && isValidEmail(email) && name.length > 0) {
        sendToFirebase();
        setSubmitStatus(true);
    }
  };

  function isValidEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    return emailPattern.test(email);
  }

  const button = (<Button class="form-submit-button" id="main-button" sx={{
        background: theme.colors.button.primary,
        '&:hover': {
            background: theme.colors.button.hover, // Apply the hover color
          },
    }}
    onClick={handleSubmission}>Join the list</Button>);

  const successButton = (<Button class="form-submit-button" id="main-button" color="success">Thank you {name} for your submission!</Button>);

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updateName = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send email using an API, etc.
    console.log('Email submitted:', email);
  };

  return (
    <div>
      <div class="center">
        <h1 class="form-header">Let's talk!</h1>
      </div>
        <form onSubmit={handleSubmit}>
          <div class="email-form-input">
            <Input placeholder="" startDecorator={<label>First/Last Name:</label>} onChange={updateName} value={name}/>
          </div> 
          
          <div class="email-form-input">
            <Input placeholder="" startDecorator={<label>Email:</label>} onChange={updateEmail} value={email}/>
          </div>
        <div class="form-spacing">
            {userDidSumbit ? successButton : button}
        </div>
        </form>
    </div>
  );
}

export default EmailForm;